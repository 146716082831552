import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { alertsReducer } from "./reducers/alertReducer";
import { blogReducer } from "./reducers/blogReducer";
import { eventReducer } from "./reducers/eventReducer";
import { authReducer } from "./reducers/authReducer";
const composeEnhancers = composeWithDevTools({

});
const rootReducer = combineReducers({
    alertsReducer,
    blogReducer,
    eventReducer,
    authReducer
})
const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
)

export default store