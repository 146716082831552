import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
const ProtectedRoute = () => {

    const location = useLocation()

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    console.log("usepro", user?.token)

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])

    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    console.log("islogggg", isLoggedIn)
    const checkUserToken = () => {
        const userToken = user?.token;
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/');
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken(isLoggedIn);
    }, [isLoggedIn]);

    return (
        <React.Fragment>
            {
                isLoggedIn ? <Outlet /> : <> <checkUserToken /></>
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;