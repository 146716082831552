import * as api from "../../api";

import { FETCH_ALLBLOG, DELETEBLOG, UPDATEBLOG, CREATEBLOG } from "../constants/actionTypes.js";

export const getBlogs = () => async (dispatch) => {
    try {
        const { data } = await api.fetchBlogs();
        dispatch({ type: FETCH_ALLBLOG, payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const createBlog = (values, setSpinner, clearBlog) => async (dispatch) => {
    try {
        const { data } = await api.createBlog(values);
        dispatch({ type: CREATEBLOG, payload: data });
        setSpinner(false)
        clearBlog()
        alert("Blog created successfully.")

    } catch (error) {
        setSpinner(false)
        // alert('Ohpps, unable to create new blog. Check your internet connection.')

        console.log(error)
    }
}

export const updateBlog = (id, post, setSpinner, clearBlog) => async (dispatch) => {
    try {
        const { data } = await api.updateBlog(id, post);
        dispatch({ type: UPDATEBLOG, payload: data });
        setSpinner(false)
        alert('blog updated successfully')
        clearBlog()
    } catch (error) {
        console.log(error);
        setSpinner(false)
        alert('Ohpps, unable to create new BLOG. Check your internet connection.')
    }
}

export const deleteBlog = (id, setSpinner) => async (dispatch) => {
    try {
        await api.deleteBlog(id);
        dispatch({ type: DELETEBLOG, payload: id });
        setSpinner(false)
        alert("BLOG deleted successfully. Refresh your browser to view")
    } catch (error) {
        setSpinner(false)
        alert("0hpps, Unable to delete BLOG")
        console.log(error)
    }
}