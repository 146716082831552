import { AUTH } from "../constants/actionTypes";
import * as api from "../../api";


export const signin = (values, navigate, setSpinner) => async (dispatch) => {
    try {
        const { data } = await api.signIn(values);
        dispatch({ type: AUTH, data });
        setSpinner(false)
        navigate('/dashboard');
    } catch (error) {
        setSpinner(false)
        if (error?.response?.data?.message === "Something Went wrong") {
            alert("Ohpps!, Check your internet connetion")

        } else {
            alert("Email or password is not correct ")
        }
        console.log(error)
    }
}

export const signup = (values, navigate, setSpinner) => async (dispatch) => {
    try {
        const { data } = await api.signUp(values);
        dispatch({ type: AUTH, data });
        setSpinner(false)
        navigate('/')
    } catch (error) {
        setSpinner(false)
        alert("Ohpps, unable to register account, check your internet connection")
        console.log(error)
    }
}