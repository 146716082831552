import { FETCH_ALLBLOG, DELETEBLOG, UPDATEBLOG, CREATEBLOG } from "../constants/actionTypes.js";

export const blogReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_ALLBLOG:
            return action.payload;
        case CREATEBLOG:
            return [...state, action.payload];
        case UPDATEBLOG:
            return state.map((post) => post._id === action.payload._id ? action.payload : post);
        case DELETEBLOG:
            return state.filter((post) => post._id !== action.payload)
        default:
            return state;
    }
}

