import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { signin } from '../redux/actions/auth';
import { AiFillEyeInvisible } from "react-icons/ai"
// import {signin} from "../../actions/auth"

function Signin() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const [values, setValues] = useState({
    email: "",
    password: "",
  })

  const handleChange = (ev) => {
    setValues({
      ...values,
      [ev.target.name]: ev.target.value,
    });
  };

  const [spinner, setSpinner] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setSpinner(true)
    dispatch(signin(values, navigate, setSpinner))
    // console.log("values", values)
  }

  return (
    <div className='container'>
      <div className='content-container'>
        <div className='content-display'>
          <div className='form-input'>
            <div className='row'>
              <div className='col-lg-1 col-md-1 col-sm-12'></div>
              <div className='col-lg-10 col-md-10 col-sm-12'>
                <div className='image'>
                  <img src="https://res.cloudinary.com/hayteetech/image/upload/v1645891711/Mynewdawnschool/newdawnlogo_adqqj9.jpg" alt="New Dawn Logo" />
                </div>
                <div className='subtitle'>Login to Newdawn admin web</div>
                <form onSubmit={handleSubmit} >
                  <div className='inputfield-div'>
                    <label htmlFor="name">Email</label>
                    <input type="email" name="email" placeholder="Email ...." required className='inputfield' onChange={handleChange} />
                  </div>
                  <div className='inputfield-div'>
                    <label htmlFor="name">Password</label>
                    <div className="password">
                      <input type={showPassword ? "text" : "password"} name="password" placeholder="Password ...." value={values.password} required className='inputfield' onChange={handleChange} />
                      <span onClick={() => setShowPassword(prev => !prev)} className="showPassword"><AiFillEyeInvisible className={showPassword ? "lighticon" : "darkicon"} /></span>
                    </div>

                  </div>

                  {spinner ?
                    <button type="submit" className='submitButton disable-btn'>
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="sr-only"></span>
                      </div>
                      <span>Submit</span>
                    </button>
                    :
                    <button type="submit" className='submitButton'>Submit</button>
                  }


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Signin