import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Signin from '../authen/login.js';
import Register from '../authen/register.js';
import BlogForm from '../blog/blogform.js';
import BlogView from '../blog/blogview.js';

import Dashboard from '../Dashboard/Dashboard.js';
import Error from '../error/error.js';
import EditGalleryForm from '../Event/editGallery.js';
import EventForm from '../Event/eventform.js';
import EventView from '../Event/eventview.js';
import ProtectedRoute from '../util/protectedRoute.js';

// import ProductDetails from '../LearnRedux/container/ProductDetails.js';
// import ProductListing from '../LearnRedux/container/ProductListing.js';

const Router = () => {
    const [currentId, setCurrentId] = useState(null);

    return (
        <Routes>
            {/* pages */}
            <Route exact path="/" element={<Signin />} />
            {/* <Route exact path="/register" element={<Register />} /> */}

            <Route element={<ProtectedRoute />}>
                <Route exact path='/dashboard' element={<Dashboard />} />
                <Route exact path="/add_blog" element={<BlogForm currentId={currentId} setCurrentId={setCurrentId} />} />
                <Route exact path="/blogs" element={<BlogView currentId={currentId} setCurrentId={setCurrentId} />} />


                <Route exact path="/add_gallery" element={<EventForm currentId={currentId} setCurrentId={setCurrentId} />} />
                <Route exact path="/gallerys" element={<EventView currentId={currentId} setCurrentId={setCurrentId} />} />
                <Route exact path="/edit_gallery" element={<EditGalleryForm currentId={currentId} setCurrentId={setCurrentId} />} />
                <Route path="*" element={<Error />} />
            </Route>
        </Routes>
    )
}
export default Router