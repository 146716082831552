import * as api from "../../api";
import { FETCH_ALLEvent, DELETEEvent, UPDATEEvent, CREATEEvent } from "../constants/actionTypes.js";

export const fetchEvent = () => async (dispatch) => {
    try {
        const { data } = await api.fetchEvent();
        dispatch({ type: FETCH_ALLEvent, payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const createEvent = (values, setSpinner, clearGallery) => async (dispatch) => {
    try {
        const { data } = await api.createEvent(values);
        dispatch({ type: CREATEEvent, payload: data });
        setSpinner(false)
        alert("Gallery created successfully.")
        clearGallery()
    } catch (error) {
        alert('Ohpps, unable to create new gallery. Check your internet connection.')
        setSpinner(false)
        console.log(error)
    }
}

export const updateEvent = (id, post, clearEvent, setSpinner) => async (dispatch) => {
    try {
        const { data } = await api.updateEvent(id, post);
        dispatch({ type: UPDATEEvent, payload: data });
        setSpinner(false);
        alert("Gallery updated successfully.")
        clearEvent()
    } catch (error) {
        clearEvent()
        console.log(error);
        alert('Ohpps, unable to update gallery. check your internet connection')
    }
}

export const deleteEvent = (id, setSpinner) => async (dispatch) => {
    try {
        await api.deleteEvent(id);
        dispatch({ type: DELETEEvent, payload: id });
        alert("Gallery deleted successfully. Refresh your browser to view")
        setSpinner(false)
    } catch (error) {
        alert("0hpps, Unable to delete Gallery");
        setSpinner(false)
        console.log(error)
    }
}