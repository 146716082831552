import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import "./Navbar.css";
import { useDispatch } from "react-redux";


function Navbar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    console.log("userrf", user?.token)

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
    }, [location])

    const logout = () => {

        dispatch({ type: "LOGOUT" });
        setTimeout(() => {

            navigate("/")
            setUser(null)
        }, 300)
    }
    return (
        <div className='navbar'>
            <div className='container'>
                <div className='nav-container'>
                    <div className='image'>
                        <img src="https://res.cloudinary.com/hayteetech/image/upload/v1645891711/Mynewdawnschool/newdawnlogo_adqqj9.jpg" alt="New Dawn Logo" />
                    </div>

                    <div className="right">
                        {user ? (
                            <div className="name">Welcome {user?.result?.firstName} {user?.result?.lastName}</div>
                        ) : <div className='name'>Welcome User</div>}

                        <button className="logout" onClick={logout}>Logout</button>
                    </div>




                </div>
            </div>
        </div>
    )
}

export default Navbar