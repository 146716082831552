import { FETCH_ALLEvent, DELETEEvent, UPDATEEvent, CREATEEvent } from "../constants/actionTypes.js";

export const eventReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_ALLEvent:
            return action.payload;
        case CREATEEvent:
            return [...state, action.payload];
        case UPDATEEvent:
            return state.map((post) => post._id === action.payload._id ? action.payload : post);
        case DELETEEvent:
            return state.filter((post) => post._id !== action.payload)
        default:
            return state;
    }
}