import React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import "./dashboard.css"

function Dashboard() {
    return (
        <>
            <Navbar />

            <div className='dashboard container'>
                <div className='row'>
                    <div className='col-lg-2 col-md-1 col-sm-12'></div>
                    <div className='col-lg-8 col-md-10 col-sm-12'>

                        <div className='dashboard-page'>
                            <div className='container'>

                                <div className='row dashboardrow'>

                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <NavLink to="/add_blog" className='dashboard-content dashboardContent5'>
                                            <div className="dashboard-nav">Add Blog</div>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <NavLink to="/blogs" className='dashboard-content dashboardContent6'>
                                            <div className="dashboard-nav">View Blogs</div>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <NavLink to="/add_gallery" className='dashboard-content dashboardContent7'>
                                            <div className="dashboard-nav">Add Gallery</div>
                                        </NavLink>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <NavLink to="/gallerys" className='dashboard-content dashboardContent8'>
                                            <div className="dashboard-nav">View Gallery</div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-1 col-sm-12'></div>
                </div>
            </div>
        </>
    )
}

export default Dashboard