import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Router from './router/router'
// import Header from './LearnRedux/container/Header';


// import Home from './pages/home/home';

function App() {
  return (
    <div className="App">

      <Router />

    </div>
  );
}

export default App;
