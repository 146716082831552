import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Navbar from '../Navbar/Navbar';
import { createEvent, updateEvent } from "../redux/actions/eventaction";


function EventForm({ currentId, setCurrentId }) {
    const post = useSelector((state) => currentId ? state.eventReducer.find((p) => p._id === currentId) : null);

    const [values, setValues] = useState({
        title: "",
        image: "",

    })

    useEffect(() => {
        if (post) setValues(post)
    }, [post])


    const dispatch = useDispatch();
    const handleChange = (ev) => {
        setValues({
            ...values,
            [ev.target.name]: ev.target.value,
        });
    };

    const [spinner, setSpinner] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault();
        setSpinner(true)
        dispatch(createEvent(values, setSpinner, clearGallery));

    }

    const clearGallery = () => {
        setCurrentId(null);
        setValues({
            title: "",
            image: ""
        })
    }
    return (
        <div>
            <Navbar />
            <div className='container'>
                <div className='content-container'>
                    <div className='content-display'>
                        <h2>Add New Gallery </h2>

                        <div className='form-input'>
                            <div className='row'>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                                <div className='col-lg-10 col-md-10 col-sm-12'>
                                    <form onSubmit={handleSubmit} >

                                        <div className='input-div'>
                                            <label > Event title</label>
                                            <input type="text" placeholder="Event title" name="title" value={values.title} required className='inputfield' onChange={handleChange} />
                                        </div>

                                        <div className='input-div'>
                                            <label>Event url</label>
                                            <input type="url" placeholder="Event url (if any)" name="image" className='inputfield' value={values.image} onChange={handleChange} />
                                        </div>


                                        <div className='button-div'>
                                            <button onClick={clearGallery} className="submitButton clear">Clear</button>

                                            {spinner ?
                                                <button type="submit" className='submitButton disable-btn'>
                                                    <div class="spinner-grow spinner-grow-sm" role="status">
                                                        <span class="sr-only"></span>
                                                    </div>
                                                    <span>Submit</span>
                                                </button>
                                                :
                                                <button type="submit" className='submitButton'>Submit</button>
                                            }

                                        </div>

                                    </form>
                                </div>
                                <div className='col-lg-1 col-md-1 col-sm-12'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventForm