import axios from 'axios';

const API = axios.create({ baseURL: 'https://newdawnadmin.onrender.com' })

//joy agunbiade blog
export const fetchBlogs = () => API.get('/blog');
export const createBlog = (newPost) => API.post('/blog', newPost);
export const updateBlog = (id, updatedPost) => API.patch(`/blog/${id}`, updatedPost);
export const deleteBlog = (id) => API.delete(`/blog/${id}`);


//joy agunbiade event

export const fetchEvent = () => API.get('/gallery');
export const createEvent = (newPost) => API.post('/gallery', newPost);
export const updateEvent = (id, updatedEvent) => API.patch(`/gallery/${id}`, updatedEvent);
export const deleteEvent = (id) => API.delete(`/gallery/${id}`);


export const signIn = (values) => API.post('/user/signin', values)
export const signUp = (values) => API.post('/user/signup', values)