import React, { useState, useEffect } from 'react';
import moment from "moment"
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getBlogs, deleteBlog } from "../redux/actions/blogaction";
import Navbar from '../Navbar/Navbar';

function BlogView({ currentId, setCurrentId }) {
    const dispatch = useDispatch();

    const posts = useSelector((state) => state.blogReducer)
    console.log(posts)

    useEffect(() => {
        dispatch(getBlogs())
    }, [currentId, dispatch])

    const [spinner, setSpinner] = useState(false)
    const deleteItem = (post) => {
        // setSpinner(true)
        dispatch(deleteBlog(post._id, setSpinner))

    }

    return (
        <div>
            <Navbar />
            {!posts.length ? <div className="loading" style={{ paddingBottom: "30px" }}></div> : (
                <div className='post-content container'>
                    <div className='title'>New-Dawn Blogs</div>
                    <div className='row g-4'>
                        {posts.sort((a, b) => moment(new Date(b.id)) - moment(new Date(a.id))).map((post, i) => (
                            <div className='col-lg-3 col-md-4 col-sm-6' key={i}>
                                <div className='card'>
                                    <div className='imageview'>
                                        <img src={post.image} />
                                    </div>

                                    <div className='name'>{post.title}</div>

                                    <div className='action'>
                                        {spinner ? (<div >
                                            <button type="submit" className='del-btn delete-btn-disable'>
                                                <div class="spinner-grow spinner-grow-sm" role="status">
                                                    <span class="sr-only"></span>
                                                </div>

                                            </button>

                                        </div>) :
                                            <div className='del-btn'><span onClick={() => deleteItem(post)} className="delete-btn">Delete</span></div>

                                        }
                                        <NavLink to="/add_blog">
                                            <button onClick={() => setCurrentId(post._id)} className="edit">Edit</button>
                                        </NavLink>

                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>



            )}

        </div>
    )
}

export default BlogView