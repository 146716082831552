

// blog
export const CREATEBLOG = "CREATEBLOG";
export const UPDATEBLOG = "UPDATEBLOG";
export const DELETEBLOG = "DELETEBLOG";
export const FETCH_ALLBLOG = "FETCH_ALLBLOG";

//events
export const CREATEEvent = 'CREATEEvent';
export const UPDATEEvent = "UPDATEEvent";
export const DELETEEvent = "DELETEEvent";
export const FETCH_ALLEvent = "FETCH_ALLEvent";



export const AUTH = 'AUTH';
export const LOGOUT = "LOGOUT"