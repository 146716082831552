import React from 'react'
import { NavLink } from 'react-router-dom'
import error404 from "../image/error404.avif"

function Error() {
    return (
        <div>
            <div className='container'>
                <div className='errorpage'>
                    <img src={error404} alt="" />
                    <NavLink to="/dashboard" className="error-nav">Go to Dashboard</NavLink>
                </div>
            </div>
        </div>
    )
}

export default Error